import { toast } from "react-toastify";

const QUERY_ENDPOINT =
  "/indykite.knowledge.v1beta2.IdentityKnowledgeAPI/IdentityKnowledgeRead";

export interface Result {
  nodes: Node[] | undefined;
}

export interface Node {
  types: string[];
  externalId: string;
  isIdentity: boolean;
  properties: Property[];
}
export interface Property {
  type: string;
  value: any;
  metadata: Metadata;
}
export interface Metadata {
  assuranceLevel: number;
  verificationTime: string;
  source: string;
}

export const performInsightQuery = async (data: any): Promise<Result> => {
  const result: Result = { nodes: undefined };
  try {
    const credential = JSON.parse(data.credential);
    const baseUrl = credential.baseUrl;
    if (!baseUrl) {
      toast("baseUrl not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return result;
    }
    const token = credential.token;
    if (!token) {
      toast("token not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return result;
    }

    if (data.inputParams) {
      const inputParams = {
        inputParams: data.inputParams,
      };
      // // Merge input params with the query
      data.query = JSON.stringify({
        ...JSON.parse(data.query),
        ...inputParams,
      });
    }

    const url = baseUrl + QUERY_ENDPOINT;
    const request = await fetch(url, {
      method: "POST",
      body: data.query,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const response = await request;
    if (response.status === 200) {
      const data = await response.json();
      result.nodes = parseResponse(data);
      return result;
    } else {
      throw new Error("Could not read data.");
    }
  } catch (e) {
    console.error(e);

    toast("Error performing query.", {
      type: "error",
      position: "top-right",
    });
    return result;
  }
};

const parseResponse = (data: any): Node[] => {
  return (data.nodes || [])
    .map((node: any) => ({
      types: parseTypes(node),
      externalId: node.externalId,
      isIdentity: node.isIdentity,
      properties: parseProperties(node.properties || []),
    }))
    .sort(
      (a: Node, b: Node) =>
        a.types[0].localeCompare(b.types[0]) ||
        a.externalId.localeCompare(b.externalId)
    );
};

const parseTypes = (node: any): string[] => {
  const tags = node.tags || [];
  return [node.type, ...tags];
};

const parseProperties = (properties: any): Property[] => {
  return properties.map((p: any) => ({
    type: p.type,
    value:
      p.value.stringValue ||
      parseInt(p.value.integerValue, 10) ||
      parseFloat(p.value.doubleValue) ||
      p.value.durationValue ||
      p.value.timeValue ||
      (p.value.boolValue || 'false').toString(),
    metadata: p.metadata ? parseMetadata(p.metadata) : undefined,
  }));
};

const parseMetadata = (metadata: any): Metadata => {
  return {
    assuranceLevel: metadata?.assuranceLevel,
    verificationTime: metadata?.verificationTime,
    source: metadata?.source,
  };
};
