import { useEffect, useState } from "react";
import {
  ContentWrapper,
  Form,
  FormWrapper,
  InnerContentWrapper,
  NodeType,
  ResponseContentWrapper,
} from "./styled";
import Title from "../../../library/title";
import useConfiguration from "../../../hooks/useConfiguration";
import useQuery from "../../../hooks/useQuery";
import Label from "../../../library/label";
import { Metadata, Node, Property, Result, performInsightQuery } from "./data";
import { StyledButton } from "../../../library/button/styled";

const Content = () => {
  const { configurations } = useConfiguration();
  const { getParsedQueries } = useQuery();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<Result>();
  const [inputParams, setInputParams] = useState<any>(null);

  const queries = getParsedQueries();
  if (inputParams === null) {
    if (queries.length > 0) {
      const query = JSON.parse(queries[0].value);
      const inputParams = query.input_params || query.inputParams;
      if (Object.keys(inputParams || {}).length > 0) {
        setInputParams(inputParams);
      } else {
        setInputParams(undefined);
      }
    }
  }

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    const params = Object.keys(inputParams || {}).reduce(
      (acc: any, key: string) => {
        return {
          ...acc,
          [key]: {
            string_value: e.target[key].value,
          },
        };
      },
      {}
    );

    const formData = {
      credential: e.target.credential.value,
      query: e.target.query.value,
      inputParams: params,
    };
    const result = await performInsightQuery(formData);
    setResult(result);
    setLoading(false);
  };

  const handleQueryChange = async (e: any) => {
    const query = JSON.parse(e.target.value);
    const inputParams = query.input_params || query.inputParams;
    if (Object.keys(inputParams || {}).length > 0) {
      setInputParams(inputParams);
    } else {
      setInputParams(undefined);
    }
  };

  return (
    <ContentWrapper>
      <InnerContentWrapper>
        <Title>Insight Query</Title>
        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-2">
              <div>
                <label
                  htmlFor="credential"
                  className="text-white font-raleway font-bold"
                >
                  Configuration
                </label>
                <div>
                  <select
                    className="rounded-md border-none bg-[#222222] text-white w-full px-3 py-3"
                    id="credential"
                    name="credential"
                  >
                    {configurations.map((t) => (
                      <option key={t.id} value={t.value}>
                        {t.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <label
                  htmlFor="query"
                  className="text-white font-raleway font-bold"
                >
                  Query
                </label>
                <div>
                  <select
                    className="rounded-md border-none bg-[#222222] text-white w-full px-3 py-3"
                    id="query"
                    name="query"
                    onChange={handleQueryChange}
                  >
                    {queries.map((t) => (
                      <option key={t.id} value={t.value}>
                        {t.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {inputParams && (
                <div className="text-white font-raleway font-bold">
                  Input parameters
                </div>
              )}
              {inputParams && (
                <div className="grid grid-cols-[auto_1fr] items-center gap-2">
                  {Object.keys(inputParams).map(
                    (key: string, index: number) => (
                      <InputParam
                        key={index}
                        value={inputParams[key].string_value || inputParams[key].stringValue}
                        id={key}
                      />
                    )
                  )}
                </div>
              )}

              <div className="shrink mt-4">
                <StyledButton type="submit">
                  {loading ? (
                    <div className="flex gap-1 items-center">
                      <i className="fas fa-spinner fa-spin"></i>
                      Searching...
                    </div>
                  ) : (
                    <div className="flex gap-1 items-center">
                      <i className="fa-regular fa-magnifying-glass"></i>
                      Perform
                    </div>
                  )}
                </StyledButton>
              </div>
            </div>
          </Form>
        </FormWrapper>
      </InnerContentWrapper>
      {result?.nodes && (
        <ResponseContentWrapper>
          {result.nodes.length === 0 && <Label>No result</Label>}
          {result.nodes.map((node: Node, key: number) => (
            <div
              className="flex flex-column gap-2 p-3 rounded-3xl bg-[#1b1b1b] w-[350px]"
              key={`NODE-${key}`}
            >
              <div className="flex gap-2 justify-center">
                {node.types.map((type: string, key: number) => (
                  <NodeType key={key}>{type}</NodeType>
                ))}
              </div>

              <div className="flex flex-col gap-1">
                <div className="flex gap-2 font-raleway font-medium">
                  <div className="text-[#ffaf4d]">external_id:</div>
                  <div className="text-white">{node.externalId}</div>
                </div>

                {node.properties.map((p: Property, key: number) => (
                  <div key={key}>
                    <CardProperty property={p} />
                    <CardMetadata metadata={p.metadata} />
                  </div>
                ))}
              </div>
              {/* <pre className="text-white">{JSON.stringify(node, null, 2)}</pre> */}
            </div>
          ))}
        </ResponseContentWrapper>
      )}
    </ContentWrapper>
  );
};

const CardProperty = ({ property }: { property: Property }) => {
  return (
    <div className="flex gap-2 font-raleway font-medium">
      <div className="text-[#ffaf4d]">{property.type}:</div>
      <div className="text-white text-ellipsis whitespace-nowrap overflow-hidden">
        {property.value}
      </div>
    </div>
  );
};

const CardMetadata = ({ metadata }: { metadata: Metadata }) => {
  if (!metadata) {
    return null;
  }
  return (
    <div className="pl-5 text-sm">
      {metadata.assuranceLevel && (
        <div className="flex gap-2 font-raleway font-medium">
          <div className="text-[#ffaf4d]">level_of_assurance:</div>
          <div className="text-white text-ellipsis whitespace-nowrap overflow-hidden">
            {metadata.assuranceLevel}
          </div>
        </div>
      )}
      {metadata.source && (
        <div className="flex gap-2 font-raleway font-medium">
          <div className="text-[#ffaf4d]">source:</div>
          <div className="text-white text-ellipsis whitespace-nowrap overflow-hidden">
            {metadata.source}
          </div>
        </div>
      )}
      {metadata.verificationTime && (
        <div className="flex gap-2 font-raleway font-medium">
          <div className="text-[#ffaf4d]">verification_time:</div>
          <div className="text-white text-ellipsis whitespace-nowrap overflow-hidden">
            {metadata.verificationTime}
          </div>
        </div>
      )}
    </div>
  );
};

const InputParam = ({ id, value }: { id: string; value: string }) => {
  const [val, setVal] = useState<string>(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVal(event.target.value);
  };

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <>
      <label htmlFor={id} className="text-white font-raleway">
        {id}:
      </label>
      <div>
        <input
          className="rounded-md border-none bg-[#222222] text-white w-full p-1"
          type="text"
          id={id}
          name={id}
          value={val}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default Content;
