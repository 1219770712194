import {
  InnerSelectionWrapper,
  LinkIcon,
  LinkTitle,
  LinksWrapper,
  NavigationWrapper,
  SelectionItem,
  Title,
  TitleWrapper,
} from "./styled";
import { Link, useLocation } from "react-router-dom";

const FUNCTION_LINKS = [
  {
    icon: <i className="fa-solid fa-diagram-project"></i>,
    title: "Insights",
    target: "/insights",
  },
  {
    icon: <i className="fa-solid fa-shield-halved"></i>,
    title: "Authorization",
    target: "/authorization",
  },
  {
    icon: <i className="fa-solid fa-handshake"></i>,
    title: "Trusted Data Access",
    target: "/tda",
  },
];

const INGEST_LINKS = [
  {
    icon: <i className="fa-solid fa-arrow-pointer"></i>,
    title: "Arrows Ingestion",
    target: "/",
  },
];

const CONFIG_LINKS = [
  {
    icon: <i className="fa-solid fa-gear"></i>,
    title: "Queries",
    target: "/queries",
  },
  {
    icon: <i className="fa-solid fa-gear"></i>,
    title: "Configurations",
    target: "/configuration",
  },
  {
    icon: <i className="fa-solid fa-right-to-bracket"></i>,
    title: "Auth0",
    target: "/login",
  },
];

const Navigation = () => {
  const location = useLocation();

  const isActive = (target: string) => {
    return location.pathname === target ? "active" : "";
  };

  return (
    <NavigationWrapper>
        <TitleWrapper>
          <Title>Toolbelt</Title>
        </TitleWrapper>
        <LinksWrapper>
          {FUNCTION_LINKS.map((link, key) => (
            <Link key={`${link.title}-${key}`} to={link.target}>
              <SelectionItem className={isActive(link.target)}>
                <InnerSelectionWrapper>
                  <LinkIcon className={isActive(link.target)}>
                    {link.icon}
                  </LinkIcon>
                  <LinkTitle className={isActive(link.target)}>
                    {link.title}
                  </LinkTitle>
                </InnerSelectionWrapper>
              </SelectionItem>
            </Link>
          ))}
        </LinksWrapper>
        <div className="border-t border-[#909090] my-2"></div>
        <LinksWrapper>
        {INGEST_LINKS.map((link, key) => (
          <Link key={`${link.title}-${key}`} to={link.target}>
            <SelectionItem className={isActive(link.target)}>
              <InnerSelectionWrapper>
                <LinkIcon className={isActive(link.target)}>
                  {link.icon}
                </LinkIcon>
                <LinkTitle className={isActive(link.target)}>
                  {link.title}
                </LinkTitle>
              </InnerSelectionWrapper>
            </SelectionItem>
          </Link>
        ))}
      </LinksWrapper>
      <div className="border-t border-[#909090] my-2"></div>
      <LinksWrapper>
        {CONFIG_LINKS.map((link, key) => (
          <Link key={`${link.title}-${key}`} to={link.target}>
            <SelectionItem className={isActive(link.target)}>
              <InnerSelectionWrapper>
                <LinkIcon className={isActive(link.target)}>
                  {link.icon}
                </LinkIcon>
                <LinkTitle className={isActive(link.target)}>
                  {link.title}
                </LinkTitle>
              </InnerSelectionWrapper>
            </SelectionItem>
          </Link>
        ))}
      </LinksWrapper>
    </NavigationWrapper>
  );
};

export default Navigation;
