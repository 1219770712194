import { ContentWrapper, FormWrapper, InnerContentWrapper } from "./styled";
import Title from "../../../library/title";
import { AUTH0_DOMAIN } from "../../..";

const Content = () => {
  const handleRedirect = (selectedProfile: string) => {
    window.location.href = `https://${AUTH0_DOMAIN}/continue${window.location.search}&profile=${selectedProfile}`;
  };

  const ProfilePicker = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const profiles = urlParams.get("profiles")?.split(",") || [];    

    return (
      <div>
        <b className="text-white">Select profile:</b>
        <div className="flex gap-5 mt-4">
          {profiles.map((profile) => (
            <button
              key={profile}
              className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold flex-1 px-3 py-3 rounded-md bg-[#222222] transition-all duration-300"
                onClick={() => handleRedirect(profile)}
            >
              {profile}
            </button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <ContentWrapper>
      <InnerContentWrapper>
        <Title>ProfilePicker</Title>
        <FormWrapper>
          <ProfilePicker />
        </FormWrapper>
      </InnerContentWrapper>
    </ContentWrapper>
  );
};

export default Content;
